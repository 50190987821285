/*
 * Copyright 2024 NXP
 */

"use client";

import * as React from "react";
import Error from "next/error";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import styles from "@/src/components/layout/Navbar/styles";
import { Box, Link, Stack } from "@mui/material";
import Image from "next/image";
import nxpLogo from "@/public/images/nxp-logo-color.svg";
import Typography from "@mui/material/Typography";

export const metadata = { title: "Not Found" };

export default function NotFound() {
  return (
    <html lang="en">
      <body>
        <AppBar sx={styles.appBar}>
          <Toolbar variant={"dense"} sx={styles.toolbar}>
            <Link href={"/"} color={"inherit"} underline={"none"}>
              <Stack direction={"row"} alignItems={"center"}>
                <Box sx={styles.logoImageContainer}>
                  <Image
                    src={nxpLogo}
                    alt={"NXP logo"}
                    width={70}
                    style={styles.nxpLogo}
                  />
                </Box>
                <Typography variant="h6" component="div" sx={styles.mainTitle}>
                  MCUXpresso SDK Builder
                </Typography>
              </Stack>
            </Link>
          </Toolbar>
        </AppBar>
        <Error statusCode={404} />
      </body>
    </html>
  );
}
