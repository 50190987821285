/*
 * Copyright 2023-2024 NXP
 */

import COLORS from "@/src/lib/colors";
import { Theme } from "@mui/material";

const styles = {
  appBar: {
    top: "auto",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    backgroundColor: COLORS.grey[800],
    justifyContent: "space-between",
  },
  feedbackLink: { textDecoration: "none" },
  feedbackInnerContainer: { "& div": { padding: "6px 0" } },
  sectionOuterContainer: { margin: 0, padding: "4px 16px" },
  feedbackTextField: { backgroundColor: COLORS.grey[100] },
  mainTitle: { flexGrow: 1 },
  menuTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  menuItemsDivider: { my: 0.5 },
  iconImage: { verticalAlign: "top" },
  internalSectionContainer: {
    display: { xs: "none", md: "flex" },
    backgroundColor: "dimgray",
    paddingInline: "15px",
    alignSelf: "stretch",
  },
  internalIcon: { verticalAlign: "top", fill: "chartreuse" },
  internalSectionLinkText: { marginLeft: "5px" },
  internalSectionLinkContainer: { padding: "0px 10px" },
  logoImageContainer: { display: { xs: "none", sm: "flex" } },
  userTab: {
    "&:hover": { color: COLORS.teal[400] },
  },
  menuDropdown: (withEndIcon?: boolean) => ({
    paper: {
      elevation: 0,
      sx: {
        minWidth: 150,
        maxWidth: "100%",
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,

        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: withEndIcon ? 7 : 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    },
  }),
  toolbar: { justifyContent: "space-between" },
  toolsButtonGroup: {
    alignSelf: "stretch",
    "& > button": {
      borderColor: "white !important",
      "&:hover": {
        backgroundColor: "dimgray",
      },
    },
  },
  nxpLogo: { marginRight: 10 },
  linkSectionTitle: {
    fontWeight: "bold",
    color: COLORS.teal[200],
    textAlign: "center",
  },
  newsItem: {
    maxWidth: "400px",
    maxHeight: "400px",
    cursor: "default",
  },
  newsList: { maxHeight: 250, overflowY: "auto" },
  collapsibleMenuBadgeText: { marginRight: 1 },
  collapsibleMenuItemsOuterContainer: { width: "auto", overflowY: "auto" },
  collapsibleMenuItemsInnerContainer: { marginY: 1 },
  inlineMenuIconsContainer: {
    display: { xs: "none", lg: "flex" },
    alignSelf: "stretch",
  },
  menuToggleButton: { display: { xs: "initial", lg: "none" } },
  showOnSmallScreen: { display: { xs: "initial", md: "none" } },
  collapsedMenuItem: { display: { xs: "flex", lg: "none" } },
  nestedNavList: { py: 0, minWidth: 350 },
  nestedNavListItemDivider: { display: { xs: "none", lg: "flex" } },
};

export default styles;
